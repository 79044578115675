import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { IconButton, Theme } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { createStyles, StyleRules, WithStyles, withStyles } from '@material-ui/styles';

import { anonymousGetAllProjectRequestOnlyId } from '../../actions/projectBidAction';
import ProjectPreviewView from '../../components/ProjectPreviewView';
import ProjectLayout from '../../layout/ProjectLayout';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    contentBox: {
      background: '#fff',
      [theme.breakpoints.up('sm')]: {
        // 当屏幕宽度 >= 600px 时应用的样式
        width: '100%',
        margin: '20px auto',
      },
      [theme.breakpoints.up('md')]: {
        // 当屏幕宽度 >= 960px 时应用的样式
        width: '800px',
        margin: '20px auto',
      },
    },
    btnRight: {
      position: 'fixed',
      zIndex: 9999,
      right: '1rem',
      top: '50%',
    },
    btnLeft: {
      position: 'fixed',
      zIndex: 9999,
      left: '1rem',
      top: '50%',
    },
  });
interface ExtendedLocation extends Location {
  state?: { examine: boolean; bidRequest: boolean; closed: boolean };
}
const ProjectPreview = ({ location, classes }: Props): React.ReactElement => {
  const dispatch = useDispatch();
  const [projectALLid, setProjectALLid] = useState<number[]>([]);
  const [requestIndex, setRequestIndex] = useState<number>(0);
  const [requestId, setRequestId] = useState<number>(0);
  const id = parseInt(location.hash.replace('#', ''));
  const box1 = useRef<HTMLDivElement>(null);
  const dataId = async () => {
    const ids = await dispatch(anonymousGetAllProjectRequestOnlyId());

    // 获取数据
    const idArray = ids.map((item: ProjectRequestInfo) => item.id);
    setProjectALLid(idArray);
    const index = idArray.indexOf(id);
    if (index != -1) {
      setRequestIndex(index);
    }
  };

  useEffect(() => {
    setRequestId(id);

    dataId();
  }, []);

  const onClickBtnNext = () => {
    if (requestIndex < projectALLid.length - 1) {
      setRequestIndex(requestIndex + 1);
      setRequestId(projectALLid[requestIndex + 1]);
    } else {
      return;
    }

    if (box1.current) {
      box1.current.style.opacity = '0';
      box1.current.style.transform = 'translateX(100%)';
      setTimeout(() => {
        if (box1.current) {
          box1.current.style.opacity = '1';
          box1.current.style.transform = 'translateX(0)';
        }
      }, 100);
    }
  };

  const onClickBtnPrev = () => {
    if (requestIndex > 0) {
      setRequestIndex(requestIndex - 1);
      setRequestId(projectALLid[requestIndex - 1]);
    } else {
      return;
    }
    if (box1.current) {
      box1.current.style.opacity = '0';
      box1.current.style.transform = 'translateX(-100%)';
      setTimeout(() => {
        if (box1.current) {
          box1.current.style.opacity = '1';
          box1.current.style.transform = 'translateX(0)';
        }
      }, 100);
    }
  };

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>
      <ProjectLayout>
        <div id="boxW" style={{ overflow: 'hidden', position: 'relative' }}>
          <IconButton aria-label="delete" className={classes.btnLeft} onClick={onClickBtnPrev}>
            <ArrowBackIosIcon fontSize="large" />
          </IconButton>
          <IconButton aria-label="delete" className={classes.btnRight} onClick={onClickBtnNext}>
            <ArrowForwardIosIcon fontSize="large" />
          </IconButton>
          <div
            id="box1"
            ref={box1}
            style={{
              width: '100%',
              transition: 'transform 0.3s ease-in-out',
            }}
          >
            {requestId && (
              <ProjectPreviewView
                requestId={requestId}
                examine={location.state?.examine ? location.state.examine : false}
                bidRequest={location.state?.bidRequest ? location.state.bidRequest : false}
                closed={location.state?.closed ? location.state.closed : false}
              ></ProjectPreviewView>
            )}
          </div>
        </div>
      </ProjectLayout>
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  location: ExtendedLocation;
}
export default withStyles(styles)(ProjectPreview);
