import React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { alpha, createStyles, StyleRules, Theme } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MoreIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from 'gatsby';
import { navigate } from 'gatsby-link';
import i18n from 'i18next';

import { logout } from '../actions/userActions';
import logo from '../images/0419Fintegrity_logo-03.png';
import { AppState } from '../reducers';
import { FUserTypeEnum, userTypeKnownAction } from '../reducers/userReducer';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    toolbar: {
      width: '100%',
      margin: 'auto',
      height: theme.spacing(3),
    },
    img: {
      height: '3rem',
      marginBottom: 0,
      marginRight: '2rem',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      minWidth: '6rem',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      minWidth: '5rem',
      [theme.breakpoints.up('md')]: {
        width: '20rem',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    usernameDisplay: {
      paddingLeft: '4px',
    },
    menuText: {
      fontSize: '1.2rem',
    },
    AppBarBackground: {
      background: '#ffffff',
      color: '#3f424f',
    },
  });

const Header = ({
  classes,
  projectDisplays = false,
  userDisplays = false,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  i18n.loadNamespaces('header');
  const dispatch = useDispatch();
  const user = useSelector((appState: AppState) => appState.user);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const [email, setEmail] = useState<string>(user.email || '');

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  isMobileMenuOpen;
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = (): void => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = (): void => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleLogout = async (): Promise<void> => {
    setAnchorEl(null);
    await dispatch(logout(user.userType));
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  handleMobileMenuOpen;
  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      style={{ zIndex: '9999' }}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {user.userType === FUserTypeEnum.COMPANY && (
        <span>
          <MenuItem
            onClick={(): void => {
              navigate('/company/info');
            }}
          >
            企业认证
          </MenuItem>

          <MenuItem
            onClick={(): void => {
              navigate('/company/verification');
            }}
          >
            企业信息
          </MenuItem>
        </span>
      )}
      {user.userType === FUserTypeEnum.CONSULTANT && (
        <span>
          <MenuItem
            onClick={(): void => {
              navigate('/consultant/verification');
            }}
          >
            {t('header:consultant_information')}
          </MenuItem>
          <MenuItem
            onClick={(): void => {
              navigate('/consultant/authentication');
            }}
          >
            {t('header:real_name_authentication')}
          </MenuItem>
        </span>
      )}

      <MenuItem onClick={handleLogout}> {t('header:sign_out')}</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {user.userType === FUserTypeEnum.COMPANY && (
        <span>
          <MenuItem
            onClick={(): void => {
              navigate('/company/info');
            }}
          >
            企业认证
          </MenuItem>

          <MenuItem
            onClick={(): void => {
              navigate('/company/verification');
            }}
          >
            企业信息
          </MenuItem>
        </span>
      )}
      {user.userType === FUserTypeEnum.CONSULTANT && (
        <MenuItem
          onClick={(): void => {
            navigate('/consultant/verification');
          }}
        >
          {t('header:consultant_information')}
        </MenuItem>
      )}

      <MenuItem onClick={handleLogout}> {t('header:sign_out')}</MenuItem>
    </Menu>
  );
  useEffect(() => {
    setEmail(user.email || '');
    return () => {
      setEmail('');
    };
  }, [user]);

  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.AppBarBackground}>
        <Toolbar className={classes.toolbar}>
          {!projectDisplays && (
            <Link
              onClick={() => {
                dispatch(userTypeKnownAction(FUserTypeEnum.CONSULTANT));
              }}
              to={
                user.userType === FUserTypeEnum.CONSULTANT
                  ? '/consultant'
                  : user.userType === FUserTypeEnum.COMPANY
                  ? '/company'
                  : '/admin'
              }
            >
              <img src={logo} alt="logo" className={classes.img} />
            </Link>
          )}
          {projectDisplays && <img src={logo} alt="logo" className={classes.img} />}
          {user.userType !== FUserTypeEnum.CONSULTANT && !projectDisplays && (
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
          )}

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
              <Typography className={classes.usernameDisplay}> {email}</Typography>
              <ArrowDropDownIcon />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {!userDisplays && renderMobileMenu}
      {!userDisplays && renderMenu}
    </div>
  );
};

export interface Props extends WithStyles<typeof styles> {
  title?: string;
  projectDisplays?: boolean;
  userDisplays?: boolean;
}

export default withStyles(styles)(Header);
